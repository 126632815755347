import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Head from '../components/head'
import PageHeading from '../components/pageHeading'

export default ({ pageContext }) => {
    const articles = pageContext.articles.sort((a, b) => a.date > b.date ? 1 : -1)

    return (
        <Layout>
            <Head title="Articles" />
            <PageHeading text="Articles" centered />

            <div className="space-y-8 max-w-xl mx-auto">
                {articles.map(article => article.published ? (
                    <Link key={article.slug} to={`/articles/${article.slug}/`} className="block">
                        <section className="rounded-lg bg-white shadow border-l-8 sm:border-l-0 sm:border-t-8 border-background-dark p-4 md:p-6 space-y-1 h-full">
                            <p className="text-gray-500 text-sm font-medium uppercase">{article.prettyDate}</p>
                            <p className="text-gray-900 sm:text-2xl text-xl font-bold">{article.title}</p>
                        </section>
                    </Link>
                ) : null)}
            </div>
        </Layout>
    )
}
